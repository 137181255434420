import React from "react";
import HelpCenterList from "./help-center-list/HelpCenterList";

const HelpCenter = () => {
  return (
    <>
      <section className="searchCenter">
        <div className="container">
          <div className="searchCenterCard">
            <h3>Hi. How can we help you?</h3>
            <input
              type="text"
              placeholder="Search the Help Center"
              className="form-control"
            />
          </div>
          <HelpCenterList />
        </div>
      </section>
    </>
  );
};

export default HelpCenter;
