import React from "react";

const VisualCategory = () => {
  return (
    <>
      <section className="visualCategorySec sectionPD">
        <div className="container">
          <div className="visualCategoryList">
            <div className="visualCategoryItem" style={{background: "#555b62"}}>
                <div className="para">
                <p>earn your daughter's smile</p>
                </div>
                <div className="img scaleImg">
                <img
                    src="http://corano.demo.towerthemes.com/image/cache/catalog/products/13-370x370.jpg"
                    alt="Jewellery"
                    className="img-fluid"
                />
                </div>
            </div>
            <div className="visualCategoryItem" style={{background: "#876354"}}>
                <div className="para">
                <p>celebrate your promotion</p>
                </div>
                <div className="img scaleImg">
                <img
                    src="http://corano.demo.towerthemes.com/image/cache/catalog/products/14-370x370.jpg"
                    alt="Jewellery"
                    className="img-fluid"
                />
                </div>
            </div>
            <div className="visualCategoryItem" style={{background: "#706670"}}>
                <div className="para">
                <p>be the star of the party</p>
                </div>
                <div className="img scaleImg">
                <img
                    src="http://corano.demo.towerthemes.com/image/cache/catalog/products/15-370x370.jpg"
                    alt="Jewellery"
                    className="img-fluid"
                />
                </div>
            </div>
            <div className="visualCategoryItem" style={{background: "#7dbdbc"}}>
                <div className="para">
                <p>achieving your fitness goal</p>
                </div>
                <div className="img scaleImg">
                <img
                    src="http://corano.demo.towerthemes.com/image/cache/catalog/products/13-370x370.jpg"
                    alt="Jewellery"
                    className="img-fluid"
                />
                </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VisualCategory;
