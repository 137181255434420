import React from "react";
import img1 from "../../../assets/img/catalog/img1-top-corano1.jpg";
import img2 from "../../../assets/img/catalog/img2-top-corano1.jpg";
import img3 from "../../../assets/img/catalog/img3-top-corano1.jpg";
import img4 from "../../../assets/img/catalog/img4-top-corano1.jpg";

const Catalog = () => {
  return (
    <>
      <section className="catalogSec sectionPD  pt-0">
        <div className="container">
          <div className="pt-block static-top-store1">
            <div className="inner">
              <div className="col">
                <div className="image">
                  <a href="earrings">
                    <img
                      src={img1}
                      alt="img1"
                    />
                  </a>
                  <div className="text text-right">
                    <h5 className="text1">Beautiful</h5>
                    <h3 className="text2">
                      Wedding <span>Rings</span>
                    </h3>
                    <a href="earrings">Shop Now</a>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="image">
                  <a href="rings">
                    <img
                      src={img2}
                      alt="img2"
                    />
                  </a>
                  <div className="text text-right">
                    <h5 className="text1">earring</h5>
                    <h3 className="text2">
                      Tangerine Floral <span>Earring</span>
                    </h3>
                    <a href="rings">Shop Now</a>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="image">
                  <a href="necklaces">
                    <img
                      src={img3}
                      alt="img3"
                    />
                  </a>
                  <div className="text text-right">
                    <h5 className="text1">New Arrivals</h5>
                    <h3 className="text2">
                      Pearl <span>Necklaces</span>
                    </h3>
                    <a href="necklaces">Shop Now</a>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="image">
                  <a href="bracelets">
                    <img
                      src={img4}
                      alt="img4"
                    />
                  </a>
                  <div className="text text-right">
                    <h5 className="text1">new design</h5>
                    <h3 className="text2">
                      Diamond <span>Jewelry</span>
                    </h3>
                    <a href="bracelets">Shop Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Catalog;
