import React from "react";
import { BiSupport } from "react-icons/bi";
import { BsArrowReturnLeft } from "react-icons/bs";
import { MdOutlineLocalShipping, MdOutlinePayment } from "react-icons/md";

const PolicyBlock = () => {
  return (
    <>
      <section className="policyBlockSec">
        <div className="container">
          <div className="policy-block">
            <div className="inner">
              <div className="col">
                <div className="icon"><MdOutlineLocalShipping /></div>
                <div className="box">
                  <div className="text">
                    <h4>Free Shipping</h4>
                    <p>Free shipping on all US order or order above $200</p>
                  </div>
                </div>
              </div>
              <div className="col ">
              <div className="icon"><BiSupport /></div>

                <div className="box">
                  <div className="text">
                    <h4>Support 24/7</h4>
                    <p>Contact us 24 hours a day, 7 days a week</p>
                  </div>
                </div>
              </div>
              <div className="col ">
              <div className="icon"><BsArrowReturnLeft /></div>

                <div className="box">
                  <div className="text">
                    <h4>30 Days Return</h4>
                    <p>Simply return it within 30 days for an exchange</p>
                  </div>
                </div>
              </div>
              <div className="col ">
                <div className="icon"><MdOutlinePayment /></div>
                <div className="box">
                  <div className="text">
                    <h4>100% Payment Secure</h4>
                    <p>We ensure secure payment with PEV</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PolicyBlock;
