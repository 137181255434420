import React from "react";
import { Link } from "react-router-dom";

const PopularSearch = () => {
  return (
    <>
      <div className="popularSearchSec sectionPD">
        <div className="container">
          <div className="headingText">
            <h3>Popular Search</h3>
          </div>

          <div className="searchNameCard">
            <div className="searchNameList">
              <h6>Gold Rate in Major Cities</h6>
              <ul>
                <li>
                  <Link to="/">Gold Rate Today In Chennai</Link>
                </li>
                <li>
                  <Link to="/">Gold Rate Today In Hyderabad</Link>
                </li>
                <li>
                  <Link to="/">Gold Rate Today In Bengalore</Link>
                </li>
                <li>
                  <Link to="/">Gold Rate Today In Mumbai</Link>
                </li>
                <li>
                  <Link to="/">Gold Rate Today In Delhi</Link>
                </li>
                <li>
                  <Link to="/">Gold Rate Today In Kerala</Link>
                </li>
                <li>
                  <Link to="/">Gold Rate Today In Pune</Link>
                </li>
                <li>
                  <Link to="/">Gold Rate Today In Vijayawada</Link>
                </li>
                <li>
                  <Link to="/">Gold Rate Today In Kolkata</Link>
                </li>
               
                <li>
                  <Link to="/">Gold Rate Today In Ahmedabad</Link>
                </li>
              </ul>
            </div>

            <div className="searchNameList">
              <h6>For Women</h6>
              <ul>
                <li>
                  <Link to="/">Rings For Women</Link>
                </li>
                <li>
                  <Link to="/">Earings For Women</Link>
                </li>
                <li>
                  <Link to="/">Bracelet For Women</Link>
                </li>
                <li>
                  <Link to="/">Bagels For Women</Link>
                </li>
                <li>
                  <Link to="/">PendantsFor Women</Link>
                </li>
                <li>
                  <Link to="/">NecklacesFor Women</Link>
                </li>                
              </ul>
            </div>

            <div className="searchNameList">
              <h6>For Men</h6>
              <ul>
                <li>
                  <Link to="/">Rings For Men</Link>
                </li>
                <li>
                  <Link to="/">Earings For Men</Link>
                </li>
                <li>
                  <Link to="/">Bracelet For Men</Link>
                </li>
                <li>
                  <Link to="/">Men's Kada</Link>
                </li>
                           
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopularSearch;
