import React from "react";
import { Link } from "react-router-dom";

const HelpCenterList = () => {
  return (
    <>
          <div className="helpCenterList">
            <ul>
              <li> <Link to="/contact">Technical Issues - Trouble Shooting Steps (Email)</Link> </li>
              <li> <Link to="/contact">BRAND Autos Dealer</Link> </li>
              <li> <Link to="/contact">User Testimonials</Link> </li>
              <li> <Link to="/contact">BRAND Autos</Link> </li>
              <li> <Link to="/contact">Buyer FAQs</Link> </li>
              <li> <Link to="/contact">Seller FAQs</Link> </li>
              <li> <Link to="/contact">General Queries</Link> </li>
              <li> <Link to="/contact">Trust and Safety</Link> </li>
              <li> <Link to="/contact">Legal & Privacy information</Link> </li>
            </ul>
          </div>
    </>
  );
};

export default HelpCenterList;
