import React from "react";
import AboutMark from "./about-mark/AboutMark";
import { Link } from "react-router-dom";
import chooseImg from "../../../assets/img/choose.jpg"

function AboutChoose() {
  return (
    <>
      <section className="featuresSection">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="chooseContent">
                <h3>BRAND - at a glance</h3>
                  <p>
                  Serving hundreds of millions of people every month, we help people buy and sell cars, find housing, get jobs, buy and sell household goods, and much more. Our person-to-person trading platforms offer easier than ever ways for people to trade.
                  </p>
                  <AboutMark />
                  <Link to={"/about"} className="btn btn-primary mt-5">More about brand</Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="glanceImg">
                <img src={chooseImg} alt="Glance" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
      </section>
    </>
  );
}

export default AboutChoose;
