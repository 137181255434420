import React from "react";
import { Link } from "react-router-dom";

const SpiritBrand = () => {
  return (
    <>
      <section className="tanishqSec sectionPD">
        <div className="container">
          <div className="tanisqhCard">
          <h4>The trust of <span className="text-transform-uppercase">Tanishq</span></h4>
          <h3>The spirit of <span className="text-transform-uppercase">JEWELLERY</span></h3>
          <p>
            The highest quality of craftsmanship and innovation, <br /> that
            brings you modern, everyday designs.{" "}
          </p>
          <div className="loadMoreButton"><Link
            to="/"
            className="btn btn-warning"
          >
            Know More
          </Link></div>
          
          </div>
        </div>
      </section>
    </>
  );
};

export default SpiritBrand;
