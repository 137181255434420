import React from 'react'

const AboutMark = () => {
  return (
    <>
        <div className="aboutMarkCard">
            <div className="aboutMarkItem">
                <strong>10 +</strong>
                <p>well-loved local brands</p>
            </div>
            <div className="aboutMarkItem">
                <strong>2M +</strong>
                <p>monthly paying listers</p>
            </div>
            <div className="aboutMarkItem">
                <strong>5M +</strong>
                <p>tonnes CO2-eq emission savings</p>
            </div>
        </div>
    </>
  )
}

export default AboutMark