import React from "react";
import img1 from "../../../assets/img/rki/social-shop/1.jpg";
import img2 from "../../../assets/img/rki/social-shop/2.jpg";
import img3 from "../../../assets/img/rki/social-shop/3.jpg";
import img4 from "../../../assets/img/rki/social-shop/4.jpg";
import img5 from "../../../assets/img/rki/social-shop/5.jpg";
import img6 from "../../../assets/img/rki/social-shop/6.jpg";
import img7 from "../../../assets/img/rki/social-shop/7.jpg";

const ShopSocial = () => {
  return (
    <>
      <section className="shopSocial sectionPD">
        <div className="container">
          <div className="fisherman-content mb-4">
            <h3>Shop Our Instagram</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit,
              facere eaque.
            </p>
          </div>
          <div className="shopSocialCard">
            <div className="shopSocialItem scaleImg">
              <img src={img1} alt="Shop" className="img-fluid" />
            </div>
            <div className="shopSocialItem scaleImg">
              <img src={img2} alt="Shop" className="img-fluid" />
            </div>
            <div className="shopSocialItem scaleImg">
              <img src={img3} alt="Shop" className="img-fluid" />
            </div>
            <div className="shopSocialItem scaleImg">
              <img src={img4} alt="Shop" className="img-fluid" />
            </div>
            <div className="shopSocialItem scaleImg">
              <img src={img5} alt="Shop" className="img-fluid" />
            </div>
            <div className="shopSocialItem scaleImg">
              <img src={img6} alt="Shop" className="img-fluid" />
            </div>
            <div className="shopSocialItem scaleImg">
              <img src={img7} alt="Shop" className="img-fluid" />
            </div>
            <div className="shopSocialItem scaleImg">
              <img src={img1} alt="Shop" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShopSocial;
