import React from 'react'
import ourBrand from "../../../assets/img/our-brand.jpg"

const OurBrand = () => {
  return (
    <>
        <section className="ourBrand sectionPD">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="glanceImg">
                            <img src={ourBrand} alt="Image" className='img-fluid' />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="brandTitle">
                            <h2>Our Brands</h2>
                            <p>We are a global leader in facilitating trade, with many well-loved, market-leading local brands.</p>
                            <p>Our consumer brands offer safe, smart, and convenient trading platforms and services for our customers. From AutoTrader to Otomoto, our innovative platforms help people trade in growth markets across Europe and South Africa. </p>

                            <a href="#" className='btn btn-warning pageBtn '>view all our brands</a>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="brandsItem mt-5">
                        <ul><li><a href="/"> <img src="https://www.abarissoftech.com/assets/img/portfolio/1.jpg" alt="Brand" /> </a></li><li><a href="/"> <img src="https://www.abarissoftech.com/assets/img/portfolio/2.jpg" alt="Brand" /> </a></li><li><a href="/"> <img src="https://www.abarissoftech.com/assets/img/portfolio/3.jpg" alt="Brand" /> </a></li><li><a href="/"> <img src="https://www.abarissoftech.com/assets/img/portfolio/4.jpg" alt="Brand" /> </a></li><li><a href="/"> <img src="https://www.abarissoftech.com/assets/img/portfolio/5.jpg" alt="Brand" /> </a></li><li><a href="/"> <img src="https://www.abarissoftech.com/assets/img/portfolio/6.jpg" alt="Brand" /> </a></li></ul>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default OurBrand