import React from "react";
import { Link } from "react-router-dom";
import aboutImg from "../../../assets/img/about.jpg";

function AboutStory() {
  return (
    <>
      <div className="aboutSection">
        <div className="storyGrid">
          <div className="storyImg">
            <img src={aboutImg} alt="About" className="img-fluid" />
          </div>
          <div className="storyText">
            <div className="storyInnerText">
              <h3 className="subtitle">
                Together we’re building a more sustainable world through trade
              </h3>
              <p>
                We build leading marketplace ecosystems enabled by tech, powered
                by trust, and loved by our customers.
              </p>

              <div className="storyButtons">
                <Link to={"/about"} className="btn btn-warning">
                  Our open roles -{" "}
                </Link>
                <Link to={"/about"} className="btn btn-warning">
                  More about us{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutStory;
