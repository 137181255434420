import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const CollectionProduct = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    // centerMode: true,centerPadding: '40px',
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <>
      <section className="collectionSec sectionPD">
        <div className="container">
          <div className="collectionList">
            <Slider {...settings}>
              <div className="collectionCard">
                <Link to="/" className="scaleImg">
                  <img
                    src="http://corano.demo.towerthemes.com/image/cache/catalog/f-cate/bracelets-405x485.jpg"
                    alt="Bracelets"
                  />
                </Link>
                <h4 className="name">
                  <Link to="/">Bracelets</Link>
                </h4>
              </div>
              <div className="collectionCard">
                <Link to="/" className="scaleImg">
                  <img
                    src="http://corano.demo.towerthemes.com/image/cache/catalog/f-cate/earrings-405x485.jpg"
                    alt="Earrings"
                  />
                </Link>
                <h4 className="name">
                  <Link to="/">Earrings</Link>
                </h4>
              </div>
              <div className="collectionCard">
                <Link to="/" className="scaleImg">
                  <img
                    src="http://corano.demo.towerthemes.com/image/cache/catalog/f-cate/necklaces-405x485.jpg"
                    alt="Necklaces"
                  />
                </Link>
                <h4 className="name">
                  <Link to="/">Necklaces</Link>
                </h4>
              </div>
              <div className="collectionCard">
                <Link to="/" className="scaleImg">
                  <img
                    src="http://corano.demo.towerthemes.com/image/cache/catalog/f-cate/rings-405x485.jpg"
                    alt="Rings"
                  />
                </Link>
                <h4 className="name">
                  <Link to="/">Rings</Link>
                </h4>
              </div>
              <div className="collectionCard">
                <Link to="/" className="scaleImg">
                  <img
                    src="http://corano.demo.towerthemes.com/image/cache/catalog/f-cate/pearls-405x485.jpg"
                    alt="Pearls"
                  />
                </Link>
                <h4 className="name">
                  <Link to="/">Pearls</Link>
                </h4>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default CollectionProduct;
