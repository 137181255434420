import React from 'react'
import { Link } from 'react-router-dom'

const SwitchContent = () => {
  return (
    <>
        <div className="switchContent">
            <div className="container">
                <div className="switchContentCard">
                    <div className="img scaleImg">
                        <img src="https://preview.itgeeksin.com/jewellery-shop/assets/img/slider/slider1.jpg" alt="Jewellery" className='img-fluid' />
                    </div>
                    <div className="text">
                        <h2>switch</h2>
                        <p>covertible jewellery by - JEWELLERY</p>
                        <Link to="/"><i className="ri-arrow-right-up-fill"></i></Link>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default SwitchContent